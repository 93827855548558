var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { triggerEventOn } from "@domql/event";
import { setContentKey, document, window } from "@domql/utils";
const getActiveRoute = (level = 0, route = window.location.pathname) => {
  const routeArray = route.split("/");
  const activeRoute = routeArray[level + 1];
  if (activeRoute) return `/${activeRoute}`;
};
let lastPathname;
let lastLevel = 0;
const defaultOptions = {
  level: lastLevel,
  pushState: true,
  initialRender: false,
  scrollToTop: true,
  scrollToNode: false,
  scrollNode: document && document.documentElement,
  scrollBody: false,
  useFragment: false,
  updateState: true,
  scrollToOffset: 0,
  contentElementKey: "content",
  scrollToOptions: { behavior: "smooth" }
};
const router = async (path, el, state = {}, options = {}) => {
  const element = el || void 0;
  const win = element.context.window || window;
  const doc = element.context.document || document;
  const opts = __spreadValues(__spreadValues(__spreadValues({}, defaultOptions), element.context.routerOptions), options);
  lastLevel = opts.lastLevel;
  const ref = element.__ref;
  if (opts.contentElementKey !== "content" && opts.contentElementKey !== ref.contentElementKey || !ref.contentElementKey) {
    ref.contentElementKey = opts.contentElementKey || "content";
  }
  const contentElementKey = setContentKey(element, opts);
  const urlObj = new win.URL(win.location.origin + path);
  const { pathname, search, hash } = urlObj;
  const rootNode = element.node;
  const route = getActiveRoute(opts.level, pathname);
  const content = element.routes[route || "/"] || element.routes["/*"];
  const scrollNode = opts.scrollToNode ? rootNode : opts.scrollNode;
  const hashChanged = hash && hash !== win.location.hash.slice(1);
  const pathChanged = pathname !== lastPathname;
  lastPathname = pathname;
  if (!content || element.state.root.debugging) {
    element.state.root.debugging = false;
    return;
  }
  if (opts.pushState) {
    win.history.pushState(state, null, pathname + (search || "") + (hash || ""));
  }
  if (pathChanged || !hashChanged) {
    if (opts.updateState) {
      await element.state.update(
        { route, hash, debugging: false },
        { preventContentUpdate: true }
      );
    }
    if (contentElementKey && opts.removeOldElement) {
      element[contentElementKey].remove();
    }
    await element.set(
      {
        tag: opts.useFragment && "fragment",
        extend: content
      },
      { contentElementKey }
    );
  }
  if (opts.scrollToTop) {
    scrollNode.scrollTo(__spreadProps(__spreadValues({}, opts.scrollToOptions || {}), {
      top: 0,
      left: 0
    }));
  }
  if (opts.scrollToNode) {
    content[contentElementKey].node.scrollTo(__spreadProps(__spreadValues({}, opts.scrollToOptions || {}), {
      top: 0,
      left: 0
    }));
  }
  if (hash) {
    const activeNode = doc.getElementById(hash);
    if (activeNode) {
      const top = activeNode.getBoundingClientRect().top + rootNode.scrollTop - opts.scrollToOffset || 0;
      scrollNode.scrollTo(__spreadProps(__spreadValues({}, opts.scrollToOptions || {}), {
        top,
        left: 0
      }));
    }
  }
  await triggerEventOn("routeChanged", element, opts);
};
var index_default = router;
export {
  index_default as default,
  getActiveRoute,
  lastLevel,
  lastPathname,
  router
};
